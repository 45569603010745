<template>
	<div class="register">
		<simple-header title="欢迎登录"></simple-header>
		<div class="page-bg pt-5">
			<div class="grid">
				<div class="warp">
					<div class="signup">
						<div class="signup-header d-flex align-items-center">
							<div class="row w-100 m-0">
								<div class="col w-50">
									<a href="javascript:;" :class="{'text-theme' : current == 0}">
										<h6 @click="onSwitch(0)" class="text-center mb-0">普通登录</h6>
									</a>
								</div>
								<div class="col w-50">
									<a href="javascript:;" :class="{'text-theme' : current == 1}">
										<h6 @click="onSwitch(1)" class="text-center mb-0">手机快捷登录</h6>
									</a>
								</div>
							</div>
						</div>
						<div class='signup-details'>
							<div v-if="tips != ''" class="ml-4 mr-4">
								<div class="alert alert-danger mt-2 mb-2 p-1">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
										class="bi bi-lightbulb" viewBox="0 0 16 16">
										<path
											d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
									</svg>
									<span class="ml-1">{{tips}}</span>
								</div>
							</div>
							<form class='signup-details_form'>
								<div v-if="current == 0">
									<div class='signup-details_form__item' :class="{'mt-0' : tips != ''}">
										<label class='icon icon-username'></label>
										<b-form-input v-model="username" class="border-left-0 rounded-0"
											placeholder="手机号码/用户名" maxlength='20' autocomplete='off'></b-form-input>
									</div>
									<div class='signup-details_form__item mb-1'>
										<label class='icon icon-password'></label>
										<b-form-input v-model="password" type="password" class="border-left-0 rounded-0"
											maxlength='16' placeholder='密码' autocomplete='off'></b-form-input>
									</div>
								</div>
								<div v-if="current == 1">
									<div class='signup-details_form__item' :class="{'mt-0' : tips != ''}">
										<label class='icon icon-phone'></label>
										<b-form-input class="border-left-0 rounded-0" v-model="mobile"
											placeholder="手机号码" maxlength='11' autocomplete='off'></b-form-input>
									</div>
									<div class='signup-details_form__item'>
										<label class='icon icon-captcha'></label>
										<b-form-input class="border-left-0 rounded-0" v-model="code" placeholder='动态码'
											maxlength='6' autocomplete='off' style="width:148px;"></b-form-input>
										<b-button class="ml-2" @click="getCode"
											:variant="seconds == 0 ? 'success' : 'secondary'" size="sm"
											:disabled="seconds > 0">{{seconds == 0 ? '获取动态码' : seconds + 'S后重试'}}
										</b-button>
									</div>
								</div>
								<div class='p-md-1 mr-3'>
									<router-link to="/findpwd" class="float-right f-14"> 忘记密码？</router-link>
									<div class="clearfix"></div>
								</div>
								<div class='signup-details_form__item mt-1'>
									<b-button @click="login()" class="w-100" variant="success">登录</b-button>
								</div>
								<div class='signup-details_form__item'>
									<div class="authorize-login register">
										<p class="clearfix authorize-login-title mt-1 mb-1">
											<span class="authorize-login-title-left">
												使用第三方账号登录
											</span>
										</p>
										<ul class="clearfix">
											<li class="authorize-login-item">
												<a class="j-authorize-login-item qq-login oauth-param" title="QQ登录"></a>
											</li>
											<li class="authorize-login-item">
												<a class="j-authorize-login-item wechat-login oauth-param"
													title="微信登录"></a>
											</li>
											<li class="authorize-login-item">
												<a class="j-authorize-login-item sina-login oauth-param"
													title="微博登录"></a>
											</li>
										</ul>
									</div>
								</div>
								<div class="login-other-ways-login register">
									<div class="text-center">
										<span>还没有账号？</span><router-link to="/register" class="text-success">免费注册</router-link>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<icp-footer></icp-footer>
	</div>
</template>

<script>
	import icpFooter from '@/components/footer/icp-footer';
	import simpleHeader from '@/components/header/simple-header';
	export default {
		name: 'login',
		data() {
			return {
				tips: '',
				timer: null,
				current: 0,
				username: '',
				password: '',
				mobile: '',
				code: '',
				seconds: 0,
			}
		},
		methods: {
			getCode() {
				if (this.verifyMobile()) {
					this.seconds = 60;
					this.timer = setInterval(() => {
						// 执行加或减函数
						if (this.seconds > 0) {
							this.seconds--;
						} else {
							clearInterval(this.timer);
						}
					}, 1000);
				}
			},
			onSwitch(i) {
				if (this.current != i) {
					this.tips = '';
					if (i == 0) {
						this.mobile = '';
						this.code = '';
					} else {
						this.username = '';
						this.password = '';
					}
				}
				this.current = i;
			},
			verifyMobile() {
				if (this.mobile == '') {
					this.tips = '请输入手机号码'
					return false
				} else {
					if (!this.$test.mobile(this.mobile)) {
						this.tips = '请输入正确的手机号码'
						return false
					}
				}
				return true;
			},
			verify() {
				if (this.current == 0) {
					if (this.username == '') {
						this.tips = '请输入用户名/手机号码'
						return false
					}
					if (this.password == '') {
						this.tips = '请输入密码'
						return false
					}
				} else {
					if(!this.verifyMobile()) {
						return false
					} 
					if(this.code == '') {
						this.tips = '请输入动态码'
						return false
					}
				}
				return true;
			},
			login() {
				if (this.verify()) {
					this.$http.post(this.$api.user.login + '/' + this.current, {
						mobile: this.current == 0 ? this.username : this.mobile,
						password: this.password,
						nicName: '游客',
						verifyCode: this.code
					}).then(res => {
						if (res.code == 1) {
							this.$store.commit('user/SET_TOKEN', res.result.token);
							let user = {
								uid: res.result.id,
								headPic: res.result.headPic,
								nickName: res.result.nicName,
								mobile: res.result.mobile,
								vip: false,
							}
							this.$store.commit('user/SET_MEMBER', user);
							this.$router.replace({
								path: '/'
							})
						} else {
							this.tips = res.reason;
						}
					})
				}

			}
		},
		components: {
			icpFooter,
			simpleHeader
		}
	}
</script>


<style scoped>
	.page-bg {
		background-image: url('~@/assets/img/lr-bg.jpg');
		height: 550px;
		width: 100%;
		position: relative;
		background-position: center, center;
		background-size: cover;
	}

	.header {
		width: 700px;
	}

	.custom-control-label,
	strong {
		font-size: 12px;
		line-height: 25px;
	}

	span {
		font-size: 14px;
	}

	.account-header {
		width: 100%;
		height: 115px;
		background-color: #fff
	}


	.grid .warp {
		float: right;
		margin-right: 40px;
		position: relative;
		overflow: hidden
	}

	input[type=text],
	input[type=password] {
		border: 1px solid #e5e5e5;
		display: inline-block;
		background-color: #fff
	}

	label.icon {
		float: left;
		display: block;
		text-align: center;
		border-top-left-radius: 2px;
		border-bottom-left-radius: 2px;
		background-repeat: no-repeat;
		background-color: #ddd;
		background-position: center
	}

	label.icon-phone {
		background-image: url('~@/assets/icon/iphone.png')
	}

	label.icon-captcha {
		background-image: url('~@/assets/icon/dynamiccode.png')
	}

	label.icon-password {
		background-image: url('~@/assets/icon/password.png')
	}

	label.icon-username {
		background-image: url('~@/assets/icon/username.png')
	}

	.signup {
		width: 332px;
		border: 1px solid #e5e5e5;
		background-color: #fff;
		position: relative;
		opacity: .95;
		filter: alpha(opacity=95)
	}

	.signup-header {
		height: 60px;
		line-height: 60px;
		border-bottom: 2px solid #00CC00;
		margin: 0 25px;
		color: #333
	}

	.signup-header_title {
		text-align: center
	}

	.signup-details {
		overflow: hidden
	}

	.signup-details_form__item {
		margin: 20px 24px 10px;
		position: relative;
		display:flex;
		justify-content: center;
		align-items: center;
	}

	.signup-details_form__item label.icon {
		width: 36px;
		height: 36px;
		line-height: 36px
	}

	.signup-details_form__item label.radio {
		cursor: pointer
	}

	.signup-details_form__item label.radio:first-of-type {
		margin-right: 45px
	}

	.signup-details_form__item input[type=text],
	.signup-details_form__item input[type=password] {
		width: 246px;
		padding-left: 4px;
		font-size: 14px;
		height: 36px;
		outline: 0
	}

	.signup-details_form__item #signup-btn {
		height: 40px;
		font-size: 16px
	}

	.signup-details_form__item.signin {
		font-size: 12px;
		color: #6c6c6c;
		line-height: 12px;
		text-align: center
	}

	label{
		margin-bottom: 0px;
	}

	.eye-icon {
		position: absolute;
		width: 32px;
		height: 32px;
		right: 3px;
		top: 2px;
		display: table-cell;
		cursor: pointer;
		text-align: center;
		background-color: #fff
	}

	.eye-open {
		background: url('~@/assets/icon/eye-open.png') no-repeat center
	}

	.eye-close {
		background: url('~@/assets/icon/eye-close.png') no-repeat center
	}


	.authorize-login.register {
		padding-bottom: 0;
		margin-top: -8px;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;
		-ms-flex-pack: justify;
		justify-content: space-between
	}

	.authorize-login-item {
		float: left;
		margin-right: 8px;
		font-size: 0
	}

	.qq-login {
		background: url('~@/assets/icon/qq.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block
	}

	.sina-login {
		background: url('~@/assets/icon/weibo.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block
	}

	.wechat-login {
		background: url('~@/assets/icon/wechat.png') no-repeat;
		width: 25px;
		height: 25px;
		display: inline-block;
		cursor: pointer
	}

	.login-other-ways-login {
		padding: 20px 26px 20px;
		margin: 0;
		border-top: dashed 1px #ebebeb;
		background-color: #f3f3f3;
		filter: alpha(opacity=95);
		opacity: .95;
		position: relative
	}



	@media screen and (max-width:1200px) {
		.grid {
			width: 100%;
			margin: 0 auto
		}
	}

	@media screen and (max-width:360px) {
		.grid {
			width: 360px;
			margin: 0 auto
		}
	}
</style>
